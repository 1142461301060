@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: #F1F4F7;
    overflow-x: hidden;
}

.hero {
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0em !important;
    padding-right: 0em !important;
}

body {
    margin: 0;
    font-size: 16px !important;
    font-weight: normal;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; */
    color: #2E2E2E !important;
    background-color: transparent !important;
}






#progress {
    position: fixed;
    top: 20px;
    left: 20px;
    transform: rotate(-90deg);
}

circle {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none;
}

.bg {
    stroke: var(--accent);
    opacity: 0.3;
}

#progress .indicator {
    stroke: var(--accent);
}

.scrollable-images {
    display: flex;
    list-style: none;
    height: 400px;
    overflow-x: scroll;
    padding: 20px 0;
    flex: 0 0 600px;
}

#scrollableimages {

    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        background: #e5e7eb;
        -webkit-border-radius: 1ex;
    }

    &::-webkit-scrollbar-thumb {
        background: #a5acba;
        -webkit-border-radius: 1ex;
    }

    &::-webkit-scrollbar-corner {
        background: #e5e7eb;
    }

}



li {
    flex: 0 0 200px;
    /* background: white; */
    margin: 0 20px 0 0;
}

li:last-of-type {
    margin: 0;
}









.line-space {
    margin-bottom: 10px;
}

a {
    color: #327cd6 !important;
}

a:hover {
    color: #327cd6 !important;
    opacity: 0.5;
    text-decoration: none !important;
}

.brat {}

.brat:hover {
    background-color: #8ACE00;
    color: #000000;
    text-transform: lowercase;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}

.mytooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.mytooltip .tooltiptext {
    visibility: hidden;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.mytooltip:hover .tooltiptext {
    visibility: visible;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

code {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.default {
    color: #000000 !important;
    text-decoration: none !important;
}

.my-blue {
    color: #2183BE;
}

.my-gray {
    color: #8f8f8f;
}

.header-title {
    padding-left: 1em;
    font-weight: 600;
}

.sticky {
    position: fixed;
}

.project-card {
    text-decoration: none !important;
}

ul.social-media-list {
    list-style-type: none;
    margin: 0;
    padding-right: 10px;
    width: 50px;
    height: 50px;
}

ul.social-media-list li {
    display: inline-block;
}

hr.rounded {
    border-radius: 5px;
}

.cover-image {
    display: block;
    width: 100%;
    margin-top: 0px;
}

.basic-img {
    display: block;
    width: 100%;
    border-radius: 5px;
}

.project-img {
    display: block;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 5px;
}

.project-img-medium {
    display: block;
    width: 80%;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 5px;
}

.project-img-small {
    display: block;
    width: 60%;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 5px;
}

.project-img-smallish {
    display: block;
    width: 45%;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 5px;
}

.project-img-smaller {
    display: block;
    width: 30%;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 5px;
}

.captionedImg {
    display: block;
    width: 100%;
    margin-top: 2em;
    border-radius: 5px;
}

.smol {
    width: 60%
}

.med {
    width: 80%
}

.image-caption {
    margin-top: 1%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    color: #828282;
}

.image-caption-center {
    margin-top: 1%;
    text-align: center;
    font-size: 14px;
    color: #828282;
}

.full-width {
    width: 100%;
}

.center {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.arrow {
    width: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
}

.paragraph {
    margin-top: 1em;
    margin-bottom: 1em;
}

blockquote {
    padding-left: 2%;
    padding-top: 1%;
}

.content {
    display: flex;
}

.about-subtitle {
    letter-spacing: -0.1px;
    font-size: 24px;
    font-weight: 600;
    margin-top: 1em;
}

.profile-image {
    margin-top: 0.7em;
    display: block;
    max-width: 100%;
    border-radius: 5px;
}

.sticky-header {
    position: sticky !important;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 9999;
}

.sticky-footer {
    position: sticky;
}

.photo {
    display: block;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 5px;
}

.background-black {
    background-color: black;
}

.icon:hover {
    fill: #2183BE !important;
}

.small-description {
    font-size: 21px;
    color: #8f8f8f;
}

@keyframes scrollFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

.home-parallax {
    height: 80%;
}

.project-title-container {
    position: relative;
    text-align: center;
    color: #F2EDE6;
    overflow: hidden;
    position: relative;
}

.bottom-left {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.link-underline {
    /* color: #327CBF !important; */
    color: #2E2E2E;
    text-decoration: underline;
    text-underline-offset: 0.25em;
    display: inline;
    position: relative;
    overflow: hidden;
    transition: 0.3s !important;
}

.link-underline:hover {
    text-decoration: underline;
    color: #2E2E2E;
    opacity: 0.5;
}

.toggle {
    background-color: #F5F5F5;
    cursor: pointer;
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    padding: 1%;
    border-radius: 10px;
    margin-bottom: 10px;
    transition: 0.3s;
}

.toggle-text {
    font-size: 14px;
    font-weight: normal;
    padding: 10px;
}

.Collapsible__trigger {
    display: block;
    text-decoration: none;
    position: relative;
    padding: 10px;
}

.Collapsible__trigger:after {
    color: #808080;
    font-size: 30px;
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 5px;
    display: block;
    transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.med-text {
    margin-left: 15%;
    margin-right: 15%;
}

/* new styling */

.section {
    margin-top: 5em;
    color: #327CBF;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.subsection {
    margin-top: 1em;
    margin-bottom: 1em !important;
    font-size: 22px;
    font-weight: 600;
}

.subsubsection {
    margin-top: 2em;
    font-size: 20px;
    font-weight: 600;
}

mark {
    background-color: #c6eaff !important;
    padding: 0 0.1em !important;
}

.project-description {
    font-size: 18px;
    margin-bottom: 0em !important;
}

.text-center {
    text-align: center;
}

.case-title {
    /* font-family: 'Be Vietnam Pro'; */
    letter-spacing: -0.1px;
    font-size: 32px;
    font-weight: 600;
    margin-top: 0em !important;
}

.shoutout {
    background-color: #b5e2f5;
    padding-left: 10%;
    padding-right: 10%;
}

.home-top {
    padding: 12px;
    margin-top: 7em;
    margin-bottom: 1em;
}

.hr-rounded-close {
    border-top: 1px solid #c2c2c2;
    border-radius: 5px;
}

.filter {
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
    color: #8f8f8f;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    padding-left: 0.75em;
    padding-right: 0.5em;
    transition: 0.3s;
}

.filter.active {
    color: black;
    background-color: #F5F5F7;
    border-radius: 5px;
    transition: 1s;
}

.filter:hover {
    cursor: pointer;
    text-decoration: none;
    color: black;
    transition: 0.3s;
}

.filter-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 1em;
}

.filter-section {
    position: -webkit-sticky;
    /* Safari */
    position: sticky !important;
    top: 0;
    margin-right: 2em;
}

.ui-sticky {
    position: static;
    -webkit-transition: none;
    transition: none;
    z-index: 800;
}

.project-info {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 3em;
    padding-left: 12em;
    padding-right: 12em;
    padding-bottom: 3em;
    /* background-color: #EBF0F3; */
    background-color: #F5F5F7;
    color: black;
}

.about-experiences {
    background-color: #F5F5F7;
    padding-left: 22%;
    padding-right: 22%;
    padding-top: 2em;
    padding-bottom: 4em;
}

.about-experience {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0em;
}

.about-experience-section {
    margin-top: 3em;
    margin-bottom: 0.5em;
    color: #666666;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.about-top {
    padding-left: 8%;
    padding-right: 8%;
    margin-top: 5em;
    margin-bottom: 5em;
}

.about-section {
    padding-left: 16%;
    padding-right: 16%;
    margin-top: 2em;
    margin-bottom: 2em;
}

.projects {
    padding-top: 1em;
}

.footer-title {
    font-size: 24px;
    /* font-family: 'Be Vietnam Pro'; */
    letter-spacing: -0.1px;
    font-weight: 600;
    color: #F2EDE6;
}

.social {
    font-size: 16px !important;
    color: #969696;
    transition: 0.3s;
}

.social:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
    transition: 0.3s;
}

b,
strong {
    font-weight: 600 !important;
}